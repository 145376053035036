<template>
  <div
    :class="['todayInfo', type == 1 ? 'custom-todayInfo' : '']"
    v-if="type == 1 || type == 3"
  >
    <div class="todayInfoBottom" v-if="type == 3"></div>
    <div :class="['Box', type == 1 ? 'unBox' : '']">
      <div class="dataBox">
        <h3 class="title">{{ $t.todayData }}</h3>
        <van-row type="flex" align="center" class="list">
          <van-col class="listLeft">
            <p class="name">{{ $t.turnover }}</p>
            <p class="num newNum">
              <span class="unit">$</span>
              {{ data.TodayOrderMoneyCount }}
            </p>
            <p class="name">{{ $t.orderQuantity }}</p>
            <p class="num btNum">{{ data.TodayOrderCount }}</p>
          </van-col>
          <van-col class="listRight">
            <p class="name">{{ $t.netIncome }}</p>
            <p class="num newNum">
              <span class="unit">$</span>
              {{ data.TodayIncomeCount }}
            </p>
            <p class="name">{{ $t.visitor }}</p>
            <p class="num btNum">{{ data.TodayVisitors }}</p>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "TodayInfo",
  props: ["data", "type"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.custom-todayInfo {
  background: transparent !important;
  padding-top: 15px !important;
}
.todayInfo {
  width: 100%;
  font-size: 24px;
  // background-image: linear-gradient(to bottom right, #61cc89, #29b899);
  // background-image: -webkit-linear-gradient(to bottom right, #61cc89, #29b899);
  background: linear-gradient(left, #71d283, #01aaa3);
  padding-top: 125px;
  position: relative;
  .todayInfoBottom {
    background-color: #f6f6f6;
    height: 130px;
    margin: 0 auto;
    position: relative;
  }
  .unBox {
    position: relative !important;
  }
  .Box {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-bottom: 0px;
    .dataBox {
      width: 92%;
      position: relative;
      margin: 0 auto;
      background-color: #ffffff;
      text-align: center;
      @include publicBox;
      .title {
        margin: 0;
        padding: 20px 0;
        font-size: 20px;
        color: #000000;
      }
      .list {
        width: 100%;
        .listLeft,
        .listRight {
          width: 50%;
          text-align: center;
          .name {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: #000000;
            font-weight: bold;
          }
          .num {
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: bold;
            color: #01aaa3;
            .unit {
              font-size: 14px;
              margin-right: 4px;
            }
          }
          .newNum {
            margin: 20px 0 20px 0;
          }
          .btNum {
            margin: 20px 0 30px 0;
          }
        }
      }
    }
  }
}
</style>
