<template>
  <div class="history" v-if="type == 2 || type == 3">
    <h3 class="title">{{ $t.historicalData }}</h3>
    <van-row type="flex" align="center" class="list">
      <van-col class="listLeft">
        <p class="name">{{ $t.cumulativeTurnover }}</p>
        <p class="num newNum">
          <span class="unit">$</span>{{ data.OrderMoneyCount }}
        </p>
        <p class="name">{{ $t.cumulativeOrder }}</p>
        <p class="num btNum">{{ data.OrderCount }}</p>
      </van-col>
      <van-col class="listRight">
        <p class="name">{{ $t.cumulativeNetIncome }}</p>
        <p class="num newNum">
          <span class="unit">$</span>{{ data.IncomeCount }}
        </p>
        <p class="name">{{ $t.cumulativeVisitors }}</p>
        <p class="num btNum">{{ data.Visitors }}</p>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "History",
  props: ["data", "type"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.history {
  width: 92%;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #ffffff;
  text-align: center;
  @include publicBox;
  .title {
    margin: 0;
    padding: 20px 0;
    font-size: 20px;
    color: #000000;
  }
  .list {
    width: 100%;
    .listLeft,
    .listRight {
      width: 50%;
      text-align: center;
      .name {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #000000;
        font-weight: bold;
      }
      .num {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: bold;
        color: #01aaa3;
        .unit {
          font-size: 14px;
          margin-right: 4px;
        }
      }
      .newNum {
        margin: 20px 0 20px 0;
      }
      .btNum {
        margin: 20px 0 30px 0;
      }
    }
  }
}
</style>
